<template>
  <div>
    <Modal
      :value="value"
      title="简历上传"
      @input="(val) => $emit('input', val)"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="姓名" prop="realName">
          <Input v-model="form.realName" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="性别" prop="sex">
          <Select v-model="form.sex" style="width: 300px">
            <Option :value="1">男</Option>
            <Option :value="2">女</Option>
          </Select>
        </FormItem>
        <FormItem label="学历" prop="education">
          <Select v-model="form.education" style="width: 300px">
            <Option :value="2">专科以下</Option>
            <Option :value="3">专科及以上</Option>
            <Option :value="4">本科</Option>
            <Option :value="5">硕士</Option>
            <Option :value="6">博士及以上</Option>
          </Select>
        </FormItem>
        <FormItem label="工作经验" prop="workExperience">
          <Input v-model="form.workExperience" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="联系电话" prop="tel">
          <Input v-model="form.tel" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="简历上传" prop="attachment">
          <upload-file
            ref="uploadResume"
            v-model="form.attachment"
            :max="1"
          ></upload-file>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="loading" @click="submit">提交</Button>
        <Button @click="() => $emit('input', false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import uploadFile from "@components/upload/upload_file";
export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    jobId: {
      type: String,
      default: "",
    },
    jobTitle: {
      type: String,
      default: "",
    },
  },
  components: {
    uploadFile,
  },
  data() {
    return {
      form: {
        realName: "",
        jobId: "",
        jobTitle: "",
        education: "",
        workExperience: "",
        tel: "",
        sex: "",
        attachment: "",
      },
      rules: {
        realName: [{ required: true, message: "请填写真实姓名" }],
        education: [{ required: true, message: "请选择学历" }],
        workExperience: [{ required: true, message: "请填写工作经验" }],
        tel: [{ required: true, message: "请填写联系电话" }],
        sex: [{ required: true, message: "请选择性别" }],
        attachment: [
          { required: true, message: "请上传简历", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    modalShow(visible) {
      if (visible) {
        this.form.jobId = this.jobId;
        this.form.jobTitle = this.jobTitle;
      } else {
        this.form = {
          realName: "",
          jobId: "",
          jobTitle: "",
          education: "",
          workExperience: "",
          tel: "",
          sex: "",
          attachment: "",
        };
        this.$refs.form.resetFields();
        this.$refs.uploadResume.clearFiles();
      }
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.loading = true;
        let params = { ...this.form };
        params.attachment = this.form.attachment.replace(/http:\/\/127.0.0.1:80\//g, '')
        this.$post(this.$api.RESUME.SEND, params)
          .then(() => {
            this.$Message.success("投递成功");
            this.$emit("send-success");
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>