<template>
  <div class="recruit-info-page">
    <div class="recruit-detail" v-if="info">
      <div class="rd-title">
        <b>{{ info.jobTitle }}</b>
      </div>
      <div class="recruit-brief">
        <div :class="['r-brief-item', responsibilitiesShow ? 'open' : 'stop']">
          <div
            :class="[
              'job-title',
              responsibilitiesShow ? 'job-title-active' : '',
            ]"
            @click="() => (responsibilitiesShow = !responsibilitiesShow)"
          >
            <span
              :class="[
                'job-duty',
                responsibilitiesShow ? 'job-duty-active' : '',
              ]"
              >岗位职责</span
            >
            <span class="release-date"
              >发布日期：{{
                `${info.releaseDate.split(" ")[0].split("-")[0]}年${
                  info.releaseDate.split(" ")[0].split("-")[1]
                }月${info.releaseDate.split(" ")[0].split("-")[2]}日`
              }}</span
            >
          </div>
          <div class="job-info" v-html="info.responsibilities"></div>
        </div>
        <div :class="['r-brief-item', jobRequireShow ? 'open' : 'stop']">
          <div
            :class="['job-title', jobRequireShow ? 'job-title-active' : '']"
            @click="() => (jobRequireShow = !jobRequireShow)"
          >
            <span :class="['job-duty', jobRequireShow ? 'job-duty-active' : '']"
              >岗位介绍</span
            >
          </div>
          <div class="job-info" v-html="info.jobRequire"></div>
        </div>
      </div>
      <div class="upload-part">
        <span class="back" @click="goBack">返回列表</span>
        <span class="sub-btn" @click="add">提交简历</span>
      </div>
    </div>

    <resume-submit
      v-model="resumeSubmitShow"
      :jobId="info ? info.jobId : ''"
      :jobTitle="info ? info.jobTitle : ''"
      @send-success="sendSuccess"
    ></resume-submit>
  </div>
</template>

<script>
import resumeSubmit from "./resumeSubmit";
export default {
  name: "",
  components: {
    resumeSubmit,
  },
  data() {
    return {
      info: null,
      responsibilitiesShow: false,
      jobRequireShow: false,
      resumeSubmitShow: false,
    };
  },
  methods: {
    getInfo() {
      this.$post(this.$api.RECRUIT.VIEW, {
        jobId: this.$route.params.jobId,
      }).then((res) => {
        this.info = res;
      });
    },
    add() {
      this.resumeSubmitShow = true;
    },
    sendSuccess() {
      this.resumeSubmitShow = false
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.getInfo();
  },
};
</script>

<style lang="less" scoped>
.recruit-info-page {
  width: 1200px;
  margin: 0 auto;
  .recruit-detail {
    margin-top: 50px;
    .rd-title {
      background: #245f5c;
      line-height: 50px;
      color: #fff;
      padding-left: 72px;
      font-size: 20px;
    }
    .recruit-brief {
      margin-top: 20px;
      .r-brief-item {
        margin-bottom: 15px;
        border: 1px solid #e6e6e6;
        max-height: 48px;
        transition: all 1s ease-in-out;
        overflow: hidden;
        .job-title {
          width: 100%;
          cursor: pointer;
          padding: 0 30px;
          height: 47px;
          background: url("../../../../public/imgs/down.png") 1155px center
            no-repeat;
          .job-duty {
            display: inline-block;
            height: 47px;
            line-height: 47px;
            margin-right: 30px;
          }
          .job-duty-active {
            color: #ff9c00;
          }
          .release-date {
            font-size: 12px;
            color: #888888;
          }
        }
        .job-title-active {
          background: url("../../../../public/imgs/down01.png") 1155px center
            no-repeat;
        }
        .job-info {
          font-size: 14px;
          line-height: 24px;
          padding: 20px 30px;
          color: #666;
          border-top: 1px solid #dddddd;
        }
      }
      .open {
        max-height: 1500px;
      }
      .stop {
        max-height: 48px;
      }
    }
    .upload-part {
      margin: 20px 0 50px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .back {
        padding-left: 50px;
        font-weight: bold;
        color: #245f5c;
        font-size: 14px;
        background: url("../../../../public/imgs/back.png") left center
          no-repeat;
        cursor: pointer;
        // margin-top: 40px;
      }
      .sub-btn {
        display: inline-block;
        width: 140px;
        height: 40px;
        border-radius: 40px;
        background: #245f5c;
        color: #fff;
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        cursor: pointer;
        &:hover {
          background: #ff9c00;
        }
      }
    }
  }
}
</style>